* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding: 0;
}
html,
body {
  background: #fff url(../images/bg-shade-00.png) repeat-x left top;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5;
  font-size: 14px;
}
fieldset,
img {
  border: 0;
}
h1 {
  font-size: 20px;
  color: #000000;
}
h2 {
  font-size: 18px;
  color: #000000;
}
h3 {
  font-size: 16px;
  color: #000000;
}
h4 {
  font-size: 14px;
  color: #000000;
}
p {
  padding: 0 0 5px 0;
}

table.contentpaneopen ul {
  margin: 0 0 10px 0;
}
table.contentpaneopen ul li ul {
  margin: 0 0 0px 0;
}
table.contentpaneopen li {
  list-style-image: url('../images/li.gif');
  padding: 0 10px 0 5px;
  margin: 0 0 0 20px;
}

.olsi-code {
  border-left: 7px solid #999999;
  border-right: 1px solid #999999;
  border-top: 3px solid #999999;
  border-bottom: 3px solid #999999;
  background-color: #dddddd;
  padding: 15px;
  width: 100%;
  height: auto;
  font-size: 12px;
  font-family: Courier News, monospace;
}

a:link,
a:visited {
  text-decoration: none;
  color: #111111;
  font-weight: bold;
}
a:hover {
  text-decoration: none;
}

#olsi-top {
  width: 1000px;
  height: 5px;
  margin-left: auto;
  margin-right: auto;
}
#olsi-top {
  color: #fff;
  width: 980px;
  height: 150px;
  text-align: left;
  background: url(../images/bck.png) no-repeat left top;
  margin-left: auto;
  margin-right: auto;
}
#olsi-top-second {
  height: 68px;
  padding: 10px 40px;
  line-height: 1.1;
  text-align: left;
  font-size: 12px;
  clear: both;
}
/* #slogan { font-family: Didot, Georgia, Garamond, �Times New Roman�, Times, serif; text-decoration: none; padding: 50px 650px 0 0; font-style: italic; font-size:36px; text-align: left; clear: both; text-shadow: -1px 0 black, 0 1px black, */
#slogan {
  color: #fff;
  font-size: 30px;
  float: left;
  padding-left: 40px;
  padding-top: 30px;
  font-weight: bold;
}
#olsi-bottom {
  font-size: 9px;
  color: #000;
  text-align: center;
  width: 1000px;
  height: 20px;
  background: transparent url(../images/back-bottom.png) no-repeat left bottom;
  margin-left: auto;
  margin-right: auto;
}
#olsi-bottom a {
  font-size: 9px;
  color: #000;
  text-align: center;
  height: 20px;
}
#olsi-bottom-menu {
  width: 980px;
  height: 22px;
  background-color: #fafafa;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
#olsi-bottom-menu-left {
  width: 750px;
  height: 22px;
  background-color: #fafafa;
  float: left;
}
#olsi-bottom-menu-right {
  padding-top: 3px;
  padding-left: 5px;
  width: 200px;
  height: 22px;
  text-align: right;
  background-color: #fafafa;
  float: right;
}
#olsi-bottom-menu ul {
  float: left;
  text-align: left;
  padding-top: 3px;
  padding-left: 5px;
}
#olsi-bottom-menu ul li {
  float: left;
  text-align: left;
}
#olsi-bottom-menu ul li a {
  font-weight: bold;
  float: left;
  text-align: left;
  font-size: 10px;
  color: #000000;
  padding: 2px 5px 0px 5px;
  border-right: 1px solid #dddddd;
}
#olsi-bottom-menu-right a {
  font-weight: bold;
  float: right;
  text-align: right;
  font-size: 10px;
  color: #000000;
  padding: 2px 5px 0px 5px;
  border-right: 1px solid #dddddd;
}

#tg {
  width: 1000px;
  background: transparent url(../images/back.png) repeat-y left top;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-align: center;
}

#olsi {
  width: 980px;
  height: auto;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#logo {
  background: url(../images/logo.png) no-repeat left top;
  padding: 50px 50px 0 0;
  width: 300px;
  height: 50px;
  position: relative;
}
#olsi-top-menu ul {
  float: left;
}
#olsi-top-menu ul li {
  float: left;
}
#olsi-top-menu ul li a {
  float: left;
  padding: 14px 4px 0px 4px;
  height: 30px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}
#tg_FastZ-w2 .search .button {
  margin-left: 5px;
}
#tg_FastZ-w2 .search .inputbox {
  border: 0;
  background: url(../images/search.png) no-repeat left top;
  height: 20px;
  width: 170px;
}
#olsi-menu {
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  height: 40px;
  text-align: left;
  list-style: none;
  position: relative;
  z-index: 1;
  background: url(../images/mainmenu.png) repeat-x left top;
}
#olsi-advert1 {
  text-align: center;
  margin: 3px;
  padding: 3px;
}
#tg_FastZ-w {
  height: 21px;
  width: 980px;
  text-align: left;
  background: #444444 url(../images/dot-4.png) repeat-x left bottom;
  padding-top: 1px;
  margin-left: auto;
  margin-right: auto;
}
#tg_FastZ-wb {
  color: #fff;
  font-weight: bold;
  height: 12px;
  padding-top: 3px;
  padding-left: 10px;
  width: 770px;
  text-align: left;
  background-color: #444444;
  float: left;
}
#tg_FastZ-wb a {
  color: #fff;
  font-weight: bold;
}
#tg_FastZ-ws {
  color: #fff;
  font-weight: bold;
  height: 15px;
  padding-top: 0px;
  padding-left: 10px;
  width: 190px;
  text-align: left;
  background-color: #444444;
  float: left;
}
#olsi-login fieldset.tg_input {
  float: left;
  padding-left: 5px;
}
#olsi-login ul li {
  float: left;
  padding-right: 16px;
}
#olsi-login ul {
  clear: both;
}
#olsi-login fieldset.tg_input p {
  float: left;
  padding-right: 3px;
}
#olsi-hi {
  float: left;
  padding-right: 10px;
}
#olsi-logout {
  float: left;
}
p#form-login-remember {
  margin-top: 19px;
  margin-right: 5px;
}
p#form-login-remember,
.olsi-button {
  margin-top: 15px;
  margin-right: 5px;
}
#olsi-center-left {
  width: 200px;
  text-align: left;
  float: left;
}
#olsi-center-right {
  width: 200px;
  text-align: left;
  float: right;
}
#olsi-bottom {
  width: 970px;
  height: 35px;
  background: url(../images/back-bottom.png) no-repeat left top;
  margin-top: 3px;
  font-size: 11px;
}
#olsi-center-center3 {
  width: 765px;
  text-align: justify;
  float: left;
}
#olsi-center-center-center3 {
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #fff;
  width: 763px;
  text-align: justify;
}
#olsi-center3 {
  width: 980px;
  background: url(../images/center-back-no-left.png) repeat-y left top;
  text-align: left;
  border-top: 5px solid #ededed;
  border-bottom: 5px solid #ededed;
}
#olsi-center-center2 {
  margin-left: 5px;
  float: left;
  width: 765px;
  text-align: justify;
}
#olsi-center-center-center2 {
  float: left;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #fff;
  width: 763px;
  text-align: justify;
}
#olsi-center2 {
  width: 980px;
  background: url(../images/center-back-no-right.png) repeat-y left top;
  text-align: left;
  border-top: 5px solid #ededed;
  border-bottom: 5px solid #ededed;
}
#olsi-center4 {
  width: 980px;
  background: url(../images/center-back-no-right.png) repeat-y left top;
  text-align: left;
  border-top: 5px solid #ededed;
  border-bottom: 5px solid #ededed;
}
#olsi-center-center1 {
  width: 980px;
  text-align: justify;
}
#olsi-center-center-center1 {
  padding-left: 5px;
  padding-right: 5px;
  background-color: #fff;
  width: 968px;
  text-align: justify;
}
#olsi-center1 {
  width: 980px;
  background-color: #fff;
  text-align: left;
  border-top: 5px solid #ededed;
  border-bottom: 5px solid #ededed;
}
#olsi-center-center4 {
  width: 560px;
  text-align: left;
  float: left;
}
#olsi-center-center-center4 {
  background-color: #fff;
  width: 547px;
  padding: 0px 7px 0px 7px;
  margin-left: 10px;
}

#olsi-user-top a {
  color: #fff;
}
#olsi-user-bottom a {
  color: #fff;
}
#olsi-user-top {
  /* background: #444444 url(../images/user-back.png) repeat-x left bottom; */
  width: 980px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  clear: both;
  padding-bottom: 10px;
}
#olsi-user-top table.contentpaneopen {
  background: none;
}
#olsi-user-bottom table.contentpaneopen {
  background: none;
}
#olsi-user-bottom table.contentpaneopen img {
  padding: 1px;
}
#olsi-user-top table.contentpaneopen img {
  padding: 1px;
}
#olsi-user-bottom {
  /* background: #fafafa url(../images/user-back.png) repeat-x left bottom; */
  width: 980px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  clear: both;
  padding-bottom: 10px;
}

.olsi-user1 h3,
.olsi-olsi h3,
.olsi-user3 h3,
.olsi-user4 h3 {
  color: #fff;
}
.olsi-user1 {
  color: #fff;
  padding: 10px 0px 0px 10px;
  float: left;
  width: 470px;
}
.olsi-user1 img {
  padding: 1px;
  margin-right: 3px;
  border: 1px solid #cbc3b0;
}
.olsi-olsi {
  color: #fff;
  padding: 10px 10px 0px 10px;
  float: right;
  width: 470px;
}
.olsi-olsi img {
  padding: 1px;
  margin-right: 3px;
  border: 1px solid #cbc3b0;
}

/* .olsi-user3 { color: #fff; padding: 10px 0px 0px 10px; float: left; width: 470px; }
.olsi-user3 img { padding: 1px; margin-right: 3px; border: 1px solid #CBC3B0; }
.olsi-user4 { color: #fff; padding: 10px 10px 0px 10px; float: right; width: 470px; }
.olsi-user4 img { padding: 1px; margin-right: 3px; border: 1px solid #CBC3B0; } */

.credit,
.credit a,
.credit a:link,
.credit a:visited {
  font-size: 10px;
  color: #fff;
  text-align: center;
  height: 1px;
  text-decoration: none;
}
.clearfix:after {
  clear: both;
  content: '.';
  display: block;
  height: 0;
  visibility: hidden;
}

* html > body .clearfix {
  display: block;
  width: 100%;
}
* html .clearfix {
  height: 1%;
}
form label {
  cursor: pointer;
}
input,
select,
textarea,
.inputbox,
.button {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
}
.inputbox {
  padding: 2px;
  border: 1px solid #cccccc;
  background: #ffffff;
  color: #000000;
  font-size: 100%;
}
.button {
  padding: 1px 8px;
  border: 1px solid #999999;
  background: #cccccc;
  color: #000000;
  font-weight: bold;
  font-size: 90%;
}
* html .button {
  /*IE 6*/
  padding: 0 5px !important;
}
* + html .button {
  /*IE 7*/
  padding: 0 5px !important;
}
.small,
.createdate {
  color: #bbbbbb;
  font-size: 11px;
}
.contentheading,
.contentheading a {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}
.contentpaneopen img {
  padding: 5px;
}
table.contentpaneopen {
  padding-bottom: 3px;
  background: url(../images/dot.gif) repeat-x left bottom;
}
a:hover.readon {
  color: #000000;
}
a.readon {
  float: right;
  font-size: 11px;
  font-weight: bold;
  padding: 4px 4px 0px 4px;
  color: #666666;
  width: 80px;
  height: 23px;
}
#olsi-center-right {
  background: url('../images/center-back-no-right.png') repeat-y scroll left top
    transparent;
  height: 1350px;
}
#olsi-center-right div.module div div div h3,
#olsi-center-left div.module div div div h3 {
  font-size: 13px;
  height: 33px;
  padding-left: 10px;
}
#olsi-center-right div.module div div div,
#olsi-center-left div.module div div div {
  width: 199px;
}
#olsi-center-right div.module div div,
#olsi-center-left div.module div div {
  padding-bottom: 11px;
}
.module_menu,
.module-vertical-menu {
  width: 199px;
}
.module_text,
.module_menu div div,
.module-vertical-menu div div {
  padding-bottom: 11px;
}
.module_vacation div div h3,
.module_text div div h3,
.module div div h3,
.module_menu div div h3,
.module-vertical-menu div div div h3 {
  color: #fff;
  padding-left: 10px;
  font-size: 13px;
  height: 33px;
  padding-top: 6px;
  background: url(../images/sidebar.png) no-repeat center top;
}
.module ul li,
.module_menu ul li,
.module-vertical-menu ul li {
  height: 30px;
  padding-left: 5px;
  padding-bottom: 1px;
  display: block;
  width: 189px;
}
.module ul li ul,
.module_menu ul li ul,
.module-vertical-menu ul li ul {
  display: none;
}
.module ul li a,
.module_menu ul li a,
.module-vertical-menu ul li a {
  background: url(../images/vertical.png) no-repeat left center;
  height: 24px;
  padding-top: 9px;
  display: block;
  padding-left: 15px;
}
.module ul li a:hover,
.module_menu ul li a:hover,
.module-vertical-menu ul li a:hover {
  background: #aaa url(../images/hover-vertical.png) no-repeat left center;
}
.module_vacation p {
  padding-left: 10px;
}
.bannergroup_text {
  padding: 5px;
  width: 180px;
}
#hornav {
  clear: both;
  display: block;
  height: 35px;
  font-weight: bold;
}
#hornav .moduletable {
  margin: 0;
}
#hornav ul {
  margin: 0px 0px 0px 11px;
  padding: 0;
  width: auto;
  float: left;
}
#hornav ul li {
  margin: 0;
  padding: 0;
  height: 35px;
  float: left;
  position: relative;
}
#hornav ul li a {
  color: #fff;
  text-transform: uppercase;
  padding: 9px 10px 6px 10px;
  height: 18px;
  display: block;
  float: left;
}
#hornav ul li a:hover {
  background-color: #444444;
  text-decoration: none;
  color: #fff;
}
#hornav ul li ul ul {
  margin: -40px 0 0 11em;
}
#hornav ul li li {
  width: 200px;
}
#hornav ul li li a {
  color: #000000;
  text-transform: none;
  display: block;
  background: none;
  width: 177px;
  border-top: 1px solid #dddddd;
  border-left: 3px solid #999999;
}
#hornav ul li li a:hover {
  background: none;
  background-color: #333333;
  left: 0;
  width: 177px;
}
#hornav ul li ul {
  left: -999em;
  margin-top: 35px;
  margin-left: 0px;
  top: 36px;
  left: 0;
  display: block;
  height: auto;
  width: 200px;
  position: absolute;
  z-index: 99;
  left: -999em;
  background-color: #d3d3d3;
  clear: left;
  margin-top: 0px;
  margin-left: 0px;
}
#hornav ul li:hover ul ul,
#hornav ul li:hover ul ul ul {
  left: -999em;
}
#hornav ul li:hover ul,
#hornav ul li li:hover ul,
#hornav ul li li li:hover ul {
  left: auto;
}
.sectiontableheader {
  height: 30px;
  background-color: #cccccc;
  padding: 3px 3px 0px 3px;
  border-top: 3px solid #333333;
  margin-top: 5px;
}
.sectiontablefooter {
  border-top: 3px solid #cccccc;
}
.sectiontableentry1 {
  height: 26px;
  background-color: #dddddd;
  border-top: 1px solid #fff;
}
.sectiontableentry1 td {
  border-top: 1px solid #fff;
  padding: 3px;
}
.sectiontableentry2 {
  height: 26px;
  background-color: #ededed;
  padding: 3px;
}
.sectiontableentry2 td {
  border-top: 1px solid #fff;
  padding: 3px;
}
table.pollstableborder td.sectiontableentry1,
table.pollstableborder td.sectiontableentry2 {
  border: none;
  background: none;
  text-align: left;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
}
.componentheading {
  font-size: 22px;
}
ul.olsi-pagination {
  float: right;
  color: #4692bf;
}
ul.olsi-pagination li {
  float: left;
  font-weight: bold;
  margin: 0px 1px 0px 1px;
}
ul.olsi-pagination li a {
  color: #333333;
  font-size: 11px;
  border: 1px solid #b4bac0;
  background-color: #ecedee;
  padding: 0px 2px 0px 2px;
}
ul.olsi-pagination li a:hover {
  color: #fff;
  border: 1px solid #4692bf;
  background-color: #4692bf;
}
#page-right,
#page-left {
  border: none;
  background-color: #fff;
  font-weight: bold;
}
